export const MELBET_VIEW_COUNT = {
  "300": {
    slidesPerView: 2,
  },
  "350": {
    slidesPerView: 1,
  },
  "710": {
    slidesPerView: 2,
  },
  "1050": {
    slidesPerView: 3,
  },
  "1279": {
    slidesPerView: 2,
  },
  "1530": {
    slidesPerView: 3,
  },
  "1800": {
    slidesPerView: 4,
  },
  "1930": {
    slidesPerView: 1,
  },
  "2100": {
    slidesPerView: 2,
  },
  "2200": {
    slidesPerView: 3,
  },
  "2300": {
    slidesPerView: 4,
  },
};

export const DEFAULT_VIEW_COUNT = {
  "270": {
    slidesPerView: 1,
  },
  "450": {
    slidesPerView: 2,
  },
  "650": {
    slidesPerView: 3,
  },
  "810": {
    slidesPerView: 2,
  },
  "969": {
    slidesPerView: 3,
  },
  "1151": {
    slidesPerView: 1,
  },
  "1251": {
    slidesPerView: 2,
  },
  "1351": {
    slidesPerView: 3,
  },
  "1920": {
    slidesPerView: 4,
  },
};

export const CYBER_VIEW_COUNT = {
  "828": {
    slidesPerView: 2,
  },
  "1366": {
    slidesPerView: 3,
  },
  "1536": {
    slidesPerView: 4,
  },
  "1920": {
    slidesPerView: 5,
  },
};
