import React, { ButtonHTMLAttributes } from 'react';
import Link from '../Link/Link';
import './Button.css';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
}

function Button(props: ButtonProps) {
  const {
    variant = 'primary',
    className = '',
    ...htmlButtonProps
  } = props;

  return (
    <Link className="btn_link">
      <button
        className={`${className} btn btn_${variant}`}
        {...htmlButtonProps}
      />
    </Link>
  );
}

export default Button;
