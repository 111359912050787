import React, { HTMLAttributes, useContext } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import { ReactComponent as DotaIcon } from "assets/icons/dota.svg";
import { ReactComponent as CsIcon } from "assets/icons/cs.svg";
import CoeffBadge from "components/Card/CoeffBadge";
import { Match } from "interfaces";
import { VariantCtx } from "App";

import LegalBetStyles from "./LegalBetCard.module.css";
import CyberCardStyles from './CyberCard.module.css';
import DefaltCardStyles from "./DefaultCard.module.css";


import Link from "components/Link/Link";
import { VidgetTypeEnum } from "enums";

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  match: Match;
  vidgetType: string | null;
}

function Card(props: CardProps) {
  const { className = "", match, vidgetType, ...htmlDivProps } = props;

  const { teams, stakes } = match;

  const getStyles = (): Record<string, string> => {
    switch (vidgetType) {
      case VidgetTypeEnum.MelBet:
        return LegalBetStyles;

      case VidgetTypeEnum.Cyber:
        return CyberCardStyles;

      default:
        return DefaltCardStyles;
    }
  }

  const styles = getStyles();

  const firstTeamStake = stakes.find(({ short_name }) => short_name === "П1");
  const secondTeamStake = stakes.find(({ short_name }) => short_name === "П2");
  const firstTeam = teams.find(({ abbreviation }) =>
    firstTeamStake?.name.includes(abbreviation || "")
  );
  const secondTeam = teams.find(({ abbreviation }) =>
    secondTeamStake?.name.includes(abbreviation || "")
  );
  const variant = useContext(VariantCtx);

  const [firstSpringCoeff] = useSpring(
    () => ({
      from: { coeff: 0 },
      coeff: firstTeamStake?.factor,
      config: config.molasses,
    }), [firstTeamStake?.factor]);

  const [secondSpringCoeff] = useSpring(
    () => ({
      from: { coeff: 0 },
      coeff: secondTeamStake?.factor,
      config: config.molasses,
    }), [secondTeamStake?.factor]);

  return (
    <div className={styles.card} {...htmlDivProps}>
      <div className={styles.card__grid_top}>
        {firstTeam?.image ? (
          <img
            src={firstTeam.image}
            alt="team_logo"
            className={styles.card__logo}
          />
        ) : (
          <span className={`${styles.card__text} ${styles.card__text_name}`}>
            {firstTeam?.name}
          </span>
        )}
        <div className={styles.card__text}>vs</div>
        {secondTeam?.image ? (
          <img
            src={secondTeam.image}
            alt="team_logo"
            className={styles.card__logo}
          />
        ) : (
          <span className={`${styles.card__text} ${styles.card__text_name}`}>
            {secondTeam?.name}
          </span>
        )}
      </div>
      <div className={styles.card__grid_bottom}>
        <Link className={styles.card__tab_link}>
          <span className={styles.card__result}>
            {firstTeamStake?.short_name}
          </span>
          <span className={styles.card__result}>
            <CoeffBadge>
              {firstSpringCoeff.coeff}
            </CoeffBadge>
          </span>
        </Link>
        {variant === "cs" && <CsIcon width={11} height={10} />}
        {variant === "dota" && <DotaIcon width={11} height={11} />}
        <Link className={styles.card__tab_link}>
          <span className={styles.card__result}>
            {secondTeamStake?.short_name}
          </span>
          <span className={styles.card__result}>
            <CoeffBadge>
              {secondSpringCoeff.coeff}
            </CoeffBadge>
          </span>
        </Link>
      </div>
    </div >
  );
}

export default Card;
