import type { ReactElement, FC } from "react";
import Button from "components/Button/Button";
import Slider from "components/Slider/Slider";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as BonusSvg } from "assets/icons/bonus.svg";
import { ReactComponent as DisclaimerSvg } from "assets/icons/disclaimer.svg";
import type { LayoutProps } from "interfaces";
import styles from "./styles.module.css";

const LegalBetLayout: FC<LayoutProps> = ({
  titleWithLineBreak,
  subtitle,
  nearestMatches,
  vidgetType,
}): ReactElement => (
  <div className={styles.container}>
    <div className={`${styles.grid__col} ${styles.mobile_disclaimer}`} />
    <div className={styles.grid__noise} />
    <div className={styles.grid}>
      <div className={`${styles.grid__col} ${styles.grid__col_description}`}>
        <Logo className={styles.logo} />
        <Button className={styles.btn_mobile}>
          <BonusSvg className={styles.bonus_mobile} />
        </Button>
      </div>
      <div className={`${styles.grid__col} ${styles.grid__col_preview}`}>
        <h1 className={styles.title}>
          {titleWithLineBreak || "Бонус \n до 7000 ₽"}
        </h1>
        <p className={styles.subtitle}>{subtitle || "на первый депозит"}</p>
      </div>
      <Slider
        className={`${styles.grid__col} ${styles.grid__col_slider}`}
        matches={nearestMatches}
        vidgetType={vidgetType}
      />
      <div className={`${styles.grid__col} ${styles.grid__col_btn}`}>
        <Button>
          <BonusSvg className={styles.bonus} />
        </Button>
      </div>
      <DisclaimerSvg
        className={`${styles.grid__col} ${styles.grid__col_disclaimer}`}
      />
    </div>
  </div>
);

export default LegalBetLayout;
