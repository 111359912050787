import { createContext, useEffect, useState, useMemo, ReactElement } from "react";
import DefaultLayout from "components/Layouts/DefaultLayout";
import LegalBetLayout from "components/Layouts/LegalBetLayout";
import CyberLayout from "components/Layouts/CyberLayout";
import { useLiveMatches } from "hooks/useLiveMatches";
import { usePrematchMatches } from "hooks/usePrematchMatches";
import { getQueryParams } from "utils/getQueryParams";
import { getUid } from "utils/getUid";
import { VidgetTypeEnum } from "enums";
import SportMessage, { LayoutProps, Match, SubscribeSport } from "interfaces";

export const NUMBER_OF_MATCHES_TO_DISPLAY = 5;

export const VariantCtx = createContext<"cs" | "dota">("cs");
const socket = new WebSocket(
  "wss://ws.betboom.ru:444/api/oddin_tree_ws/v1",
  "json"
);

function App() {
  const { type: vidgetType, sport_id, title, subtitle, size } = getQueryParams();
  const variant: "cs" | "dota" = sport_id === "od:sport:2" ? "dota" : "cs";
  const [nearestMatches, setNearestMatches] = useState<Match[] | []>([]);

  const {
    liveMatches,
    liveMatchesLoaded,
    toggleLiveMatchesLoaded,
    liveMatchesMsgHandler,
  } = useLiveMatches();

  const {
    prematchMatches,
    tournamentIds,
    prematchTournamentMsgHandler,
    prematchMatchMsgHandler,
  } = usePrematchMatches();

  const subscribeTournaments = ({ live = true }) => {
    socket.send(
      JSON.stringify({
        type: "subscribe_sport",
        subscribe_sport: {
          type: live ? "live" : "prematch",
          sport_id,
          uid: getUid(),
        },
      })
    );
  };

  useEffect(() => {
    if (!liveMatchesLoaded) return;

    if (liveMatches.length < NUMBER_OF_MATCHES_TO_DISPLAY) {
      setNearestMatches(liveMatches);
      subscribeTournaments({ live: false });
    } else {
      setNearestMatches(liveMatches.slice(0, NUMBER_OF_MATCHES_TO_DISPLAY));
    }
  }, [liveMatchesLoaded, liveMatches]);

  const observeMatches = (match: Match): void => {
    if (!match.active || !match.stakes) {
      return;
    }
    setNearestMatches((prev) =>
      prev.map((m) => (m.id === match.id ? match : m))
    );
  };

  const subscribeSportHandler = (subscribe_sport: SubscribeSport): void => {
    const { type }: SubscribeSport = subscribe_sport || {};

    if (!liveMatchesLoaded && subscribe_sport.code === 400) {
      toggleLiveMatchesLoaded(true);
    }

    switch (type) {
      case "live":
        liveMatchesMsgHandler(subscribe_sport);
        break;
      case "prematch":
        prematchTournamentMsgHandler(
          subscribe_sport,
          NUMBER_OF_MATCHES_TO_DISPLAY - liveMatches.length
        );
        break;
      default:
        break;
    }
  };

  socket.onopen = () => {
    subscribeTournaments({ live: true });
  };

  socket.onmessage = ({ data }) => {
    const message = JSON.parse(data);
    const { type }: SportMessage = message;

    switch (type) {
      case "match":
        observeMatches(message.match.match);
        break;
      case "subscribe_sport":
        subscribeSportHandler(message.subscribe_sport);
        break;
      case "subscribe_full_tournament":
        prematchMatchMsgHandler(message.subscribe_full_tournament);
        break;
      default:
        break;
    }
  };

  socket.onerror = () => { };

  /** Подписка на турниры **/
  useEffect(() => {
    if (tournamentIds.length > 0) {
      tournamentIds.map((id) =>
        socket.send(
          JSON.stringify({
            type: "subscribe_full_tournament",
            subscribe_full_tournament: {
              sport_id,
              uid: getUid(),
              tournament_id: id,
            },
          })
        )
      );
    }
  }, [tournamentIds]);

  useEffect(() => {
    setNearestMatches(
      [...liveMatches, ...prematchMatches].slice(
        0,
        NUMBER_OF_MATCHES_TO_DISPLAY
      )
    );
  }, [liveMatches, prematchMatches]);

  /** Добавление переноса строки после первого слова **/
  const titleWithLineBreak = useMemo(() => {
    const newTitle = title?.split(" ") ?? [];
    newTitle.splice(1, 0, "\n");

    return newTitle.join(" ");
  }, [title]);

  const props: LayoutProps = {
    titleWithLineBreak,
    subtitle,
    nearestMatches,
    vidgetType,
  }

  const renderLayout = (): ReactElement => {
    switch (vidgetType) {
      case VidgetTypeEnum.MelBet:
        return <LegalBetLayout {...props} />;

      case VidgetTypeEnum.Cyber:
        return <CyberLayout {...props} vidgetSize={size} />;

      default:
        return <DefaultLayout  {...props} />;
    }
  }

  return (
    <VariantCtx.Provider value={variant}>
      {renderLayout()}
    </VariantCtx.Provider>
  );
}

export default App;
