import { useState } from "react"
import { Match, SubscribeSport } from "../interfaces"

export const useLiveMatches = () => {
  const [liveMatches, setLiveMatches] = useState<Match[]>([])
  const [liveMatchesLoaded, setLiveMatchesLoaded] = useState(false);

  const liveMatchesMsgHandler = (subscribe_sport: SubscribeSport) => {
    const { sport } = subscribe_sport || {};
    const matches  = sport?.tournaments?.reduce((acc: Match[], tournament) => {
      return [...acc, ...tournament?.matches]
    }, []);

    matches && setLiveMatches(matches);
    setLiveMatchesLoaded(true);
  }

  const toggleLiveMatchesLoaded = (flag: boolean) => {
    setLiveMatchesLoaded(flag);
  }

  return {
    liveMatches,
    liveMatchesLoaded,
    toggleLiveMatchesLoaded,
    liveMatchesMsgHandler,
  }
}