import { AnchorHTMLAttributes } from 'react';
import { getQueryParams } from '../../utils/getQueryParams';
import './Link.css'

function Link(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const {
    className = '',
    ...htmlAnchorProps
  } = props;
  const {
    link,
    rest_params,
  } = getQueryParams();

  return (
    <a
      className={`${className} link`}
      href={link ? `${link}?${rest_params}` : `https://betboom.ru?${rest_params}`}
      target="_blank"
      rel="noreferrer"
      {...htmlAnchorProps}
    />
  );
}

export default Link;
