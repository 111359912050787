import React, { ReactElement, memo } from 'react'
import { SpringValue, animated } from '@react-spring/web'

interface CoeffBadgeProps {
    children: SpringValue<number>
}

const CoeffBadge: React.FC<CoeffBadgeProps> = ({
    children,
}): ReactElement => {
    return (
        <animated.span>
            {children.to((x: number) => x.toFixed(2))}
        </animated.span>
    )
}

export default memo(CoeffBadge)