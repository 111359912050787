import { ReactElement, FC, useState, useRef } from "react";
import { useTimer } from 'use-timer';
import clsx from 'clsx';
import Button from "components/Button/Button";
import Slider from "components/Slider/Slider";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as BonusSvg } from "assets/icons/bonus.svg";
import type { LayoutProps } from 'interfaces';
import styles from './styles.module.scss';
import { VidgetSizeEnum } from "enums";
import Link from "components/Link/Link";

const INTERVAL_MAIN = 5;
const INTERVAL_DISCLAIMER = 3;

const CyberLayout: FC<LayoutProps> = ({
  titleWithLineBreak,
  subtitle,
  nearestMatches,
  vidgetType,
  vidgetSize,
}): ReactElement => {
  const [showBodyDisclaimer, setShowBodyDisclaimer] = useState<boolean>(false);
  const isTouchFromEventRef = useRef<boolean>(false);

  const { start, pause, status } = useTimer({
    endTime: showBodyDisclaimer ? INTERVAL_DISCLAIMER : INTERVAL_MAIN,
    autostart: true,
    onTimeOver: () => {
      setShowBodyDisclaimer((pS: boolean) => !pS);
      start();
    },
  });

  const handleMouseEnter = (): void => {
    !isTouchFromEventRef.current && pause();
  }

  const handleMouseLeave = (): void => {
    !isTouchFromEventRef.current && start();
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
    isTouchFromEventRef.current = true;
  }

  const getIsAnimationStatusStyles = (): string =>
    status === 'PAUSED'
      ? styles.animationContainer_paused
      : styles.animationContainer_running;

  const renderBody = (): ReactElement => (
    <>
      <div className={`${styles.grid__col} ${styles.grid__col_preview} ${styles.animationContainer} ${getIsAnimationStatusStyles()}`}>
        <h1 className={styles.title}>
          {titleWithLineBreak || "Бонус \n до 7000 ₽"}
        </h1>
        <p className={styles.subtitle}>{subtitle || "на первый депозит"}</p>
      </div>
      <div className={`${styles.grid__col} ${styles.grid__col_slider} ${styles.animationContainer} ${getIsAnimationStatusStyles()} `}>
        <Slider matches={nearestMatches} vidgetType={vidgetType} vidgetSize={vidgetSize} />
      </div>
      <div className={`${styles.grid__col} ${styles.grid__col_btn} ${styles.animationContainer}  ${getIsAnimationStatusStyles()}`}>
        <Button>
          <BonusSvg className={styles.bonus} />
        </Button>
      </div>
    </>
  )

  const renderBodyDisclaimer = (): ReactElement => (
    <div className={`${styles.grid__col} ${styles.grid__col_disclaimer} ${styles.animationContainer_legal} ${getIsAnimationStatusStyles()}`}>
      <div className={styles.disclaimer}>
        <div className={styles.disclaimer__text}>
          Реклама 18+ Рекламодатель:  <Link className={styles.disclaimer__link}>betboom.ru</Link>
          <div className={styles.disclaimer__break}></div>
          {' '}
          <span className={styles.disclaimer__separator}>|</span>
          {' '}
          С подробной информацией об организаторе основанных на риске игр, пари,
          о правилах их проведения, о призовом фонде таких игр, пари,
          о количестве призов или выигрышей,
          о сроках, месте и порядке розыгрыша, получения призов или выигрышей
          можно ознакомиться на сайте <Link className={styles.disclaimer__link}>betboom.ru</Link> Сроки проведения
          акции - с 1.01.23 по 31.12.23 (GMT+3) включительно.
        </div>
      </div>
    </div>
  )

  const getSizeCn = (): string => {
    switch (vidgetSize) {
      case VidgetSizeEnum.sxs:
        return styles.container_size_sxs;

      default:
        return ''
    }
  }

  return (
    <div
      className={clsx(styles.container, { [styles.container_with_disclaimer]: showBodyDisclaimer }, getSizeCn())}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
    >
      <div className={styles.grid__noise} />
      <div className={clsx(styles.mobile__disclaimer, { [styles.mobile__disclaimer_hidden]: showBodyDisclaimer })} />
      <div className={styles.grid}>
        <div className={`${styles.grid__col} ${styles.grid__col_description}`}>
          <Logo className={styles.logo} />
          <Button className={clsx(styles.btn__mobile, { [styles.btn__mobile_hidden]: showBodyDisclaimer })}>
            <BonusSvg className={styles.bonus_mobile} />
          </Button>
        </div>
        {showBodyDisclaimer ? renderBodyDisclaimer() : renderBody()}
      </div>
    </div>
  );
}

export default CyberLayout;
