export const getQueryParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  /**
   *  od:sport:2 - dota;
   *  od:sport:3 - cs;
   */
  const type = urlParams.get("type");
  const sport_id: "od:sport:2" | "od:sport:3" | string | null =
    urlParams.get("sport_id");
  const title = urlParams.get("title");
  const subtitle = urlParams.get("subtitle");
  const link = urlParams.get("link");
  const size = urlParams.get("size");

  const rest_params = new URLSearchParams([
    ...Array.from(urlParams.entries()).filter(
      (a) => !/sport_id|title|subtitle|link|size|type/.test(a[0])
    ),
  ]).toString();

  return { type, sport_id, title, subtitle, link, rest_params, size };
};
