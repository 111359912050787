import { HTMLAttributes, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Navigation, SwiperOptions } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Match } from "interfaces";
import Card from "components/Card/Card";
import { VidgetSizeEnum, VidgetTypeEnum } from "enums";
import {
  DEFAULT_VIEW_COUNT,
  MELBET_VIEW_COUNT,
  CYBER_VIEW_COUNT,
} from "./constants";
import LegalBetSliderStyles from "./LegalBetSlider.module.css";
import CyberSliderStyles from "./CyberStyles.module.scss";
import DefaultSliderStyles from "./DefaultSlider.module.css";

interface SliderProps extends HTMLAttributes<HTMLDivElement> {
  matches: Match[];
  vidgetType: string | null;
  vidgetSize?: string | null;
}

function Slider(props: SliderProps) {
  const {
    className = "",
    matches,
    vidgetType,
    vidgetSize,
    ...htmlDivProps
  } = props;

  const swiperRef = useRef<SwiperRef>(null);

  const [initialPerView, setInitialPerView] = useState(0);
  const [showButtons, setShowButtons] = useState(true);
  const [showSwiper, setShowSwiper] = useState(false);

  useEffect(() => {
    if (matches.length && initialPerView) {
      swiperRef.current?.swiper?.update();
    }
    if (matches.length) {
      setShowSwiper(true);
    } else {
      setShowSwiper(false);
    }
  }, [matches.length, initialPerView]);

  const getBreakPoints = (): SwiperOptions["breakpoints"] => {
    switch (vidgetType) {
      case VidgetTypeEnum.MelBet:
        return MELBET_VIEW_COUNT;

      case VidgetTypeEnum.Cyber:
        return CYBER_VIEW_COUNT;

      default:
        return DEFAULT_VIEW_COUNT;
    }
  };

  const getStyles = (): Record<string, string> => {
    switch (vidgetType) {
      case VidgetTypeEnum.MelBet:
        return LegalBetSliderStyles;

      case VidgetTypeEnum.Cyber:
        return CyberSliderStyles;

      default:
        return DefaultSliderStyles;
    }
  };

  const styles = getStyles();

  const getSizeCn = (): string => {
    switch (vidgetSize) {
      case VidgetSizeEnum.sxs:
        return styles.slider_container_size_sxs;

      default:
        return "";
    }
  };

  return (
    <>
      {showSwiper && (
        <div
          className={clsx(styles.slider_container, getSizeCn())}
          {...htmlDivProps}
        >
          <div className={styles.slider_container__noise} />
          <div
            className={
              showButtons ? `${styles.navbtn} ${styles.navbtn_prev}` : ""
            }
          />
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: `.${styles.navbtn_next}`,
              prevEl: `.${styles.navbtn_prev}`,
            }}
            spaceBetween={1}
            slidesPerView={1}
            preventInteractionOnTransition={true}
            threshold={5}
            breakpoints={getBreakPoints()}
            onUpdate={(swiper) => {
              if (
                initialPerView &&
                matches.length &&
                initialPerView > matches.length
              ) {
                swiper.params.slidesPerView = matches.length;
              }
              if (
                initialPerView &&
                matches.length &&
                initialPerView <= matches.length
              ) {
                swiper.params.slidesPerView = initialPerView;
                setShowButtons(true);
              }
              if (initialPerView >= matches.length) {
                setShowButtons(false);
              }
            }}
            onBreakpoint={(swiper) => {
              if (
                swiper.params.slidesPerView &&
                typeof swiper.params.slidesPerView === "number"
              )
                setInitialPerView(swiper.params.slidesPerView);
            }}
            ref={swiperRef}
          >
            {matches.map((match) => (
              <SwiperSlide className={styles.swiper_slide} key={match.id}>
                <Card vidgetType={vidgetType} match={match} />
              </SwiperSlide>
            ))}
          </Swiper>
          {
            <div
              className={
                showButtons ? `${styles.navbtn} ${styles.navbtn_next}` : ""
              }
            />
          }
        </div>
      )}
    </>
  );
}

export default Slider;
