import { useMemo, useRef, useState } from "react"
import { NUMBER_OF_MATCHES_TO_DISPLAY } from "../App";
import { Match, SubscribeFullSport, SubscribeSport, Tournament } from "../interfaces"

export const usePrematchMatches = () => {
  const [prematchTournaments, setPrematchTournaments] = useState<Tournament[]>([]);
  const [tournamentIds, setTournamentIds] = useState<string[] | []>([]);
  const findedMatchesCount = useRef(0);

  const prematchMatches = useMemo(() => (
    prematchTournaments?.reduce((acc: Match[], tournament) => {
      return [...acc, ...tournament?.matches]
    }, [])
  ), [prematchTournaments]);

  const prematchTournamentMsgHandler = (subscribe_sport: SubscribeSport, matchesAreNecessary: number = NUMBER_OF_MATCHES_TO_DISPLAY) => {
    const { sport } = subscribe_sport || {};
    let tourIds: string[] = [];

    for (let i = 0; i < sport?.tournaments?.length; i++) {
      const currentTournament = sport?.tournaments[i];
      if (findedMatchesCount.current < matchesAreNecessary) {
        tourIds = [...tourIds, currentTournament.info.id];
      } else {
        break;
      }

      findedMatchesCount.current += currentTournament.info.matches_count;
    }

    setTournamentIds(tourIds);
  }

  const prematchMatchMsgHandler = ({ prematch }: SubscribeFullSport) => {
    const { tournament } = prematch || {};

    if (prematchTournaments.some(({ info: { id } }) => tournament.info.id === id)) {
      setPrematchTournaments((prev) => prev.map((prevTour) => (
        prevTour.info.id === tournament.info.id
          ? tournament
          : prevTour
      )));
      return;
    }

    tournament && setPrematchTournaments((prev) => [...prev, tournament]);
  }

  return {
    prematchMatches,
    tournamentIds,
    prematchTournamentMsgHandler,
    prematchMatchMsgHandler,
  }
}